import React from "react"
import { rhythm } from "../utils/typography";
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Social from "../components/social"

const Now = ({ location, data }) => {
	const siteTitle = data.site.siteMetadata.title

	return (
		<Layout location={location} title={siteTitle}>
      <SEO title="Now" description="What I'm doing now - Andrey Patseev"/>
				<div>
					<h1>What I'm doing now</h1>
					<p>A page where I'll semi-regularly note what I'm doing. Inspired by <a href="https://sive.rs/now" target="_blank">Derek Sivers.</a></p>
					<article>
					<h2>December, 2020</h2>
						<p>
							Still in process of getting a Masters Degree in Computer Science. 
							I'm interested in distributed systems (specifically, databases) and <a href="https://en.wikipedia.org/wiki/Conflict-free_replicated_data_type" target="_blank">Conflict-Free Replicated Data Types</a>. 
							But still struggling to come up with a thesis. 
							And clock is ticking - I ought to graduate in half a year. {' '}
							<i>Come on, time to do something about that.</i>
						</p>
						<p>
							Enjoying my time with an awesome team of <a href="https://avantstay.com" target="blank_">Avantstay</a>, 
							creating a product that will change hospitality business.
						</p>
					</article>
				</div>
				<hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
				<footer>
					<Social/>
				</footer>
    </Layout>
  )
}

export default Now;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`